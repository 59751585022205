$primary-color: #0061A5;
$primary-inverse: #9ECAFF;

$primary95: #E8F1FF;
$primary90: #D0E4FF;
$primary80: #9ECAFF;
$primary70: #6EAFF8;
$primary60: #5194DB;
$primary50: #317ABF;
$primary40: #0061A5;
$primary30: #00487F;
$primary20: #00325B;
$primary10: #001C37;
$primary0: #000000;

$primary-light: rgba(0, 97, 165, 0.12);

/**
 * 機能がない・非アクティブのアイコンに主に使用。
 */
$inactive-gray:  rgba(27, 27, 27, 0.38);

/**
 * アクティブのアイコンに主に使用。
 */
$active-black: #1B1B1B;

/**
 * エラー・警告系。
 */
$error: #BA1B1B;

$sidebar-background: $active-black;
$main-background: #fafafa;

$border-color: #e0e0e0;

$won: #228b22;
$lost: #ff4500;
$in-progress: #ffd700;

$won-light: rgba(74, 165, 100, 0.12);
$lost-light: rgba(186, 27, 27, 0.12);
$in-progress-light: rgba(0, 97, 165, 0.12);

$adopted: #005223;
$declined: #930006;
$reproposal-requested: #46474A;
$proposed: #00487F;

$adopted-light: rgba(74, 165, 100, 0.12);
$declined-light: rgba(186, 27, 27, 0.12);
$reproposal-requested-light: rgba(0, 0, 0, 0.08);
$proposed-light: rgba(0, 97, 165, 0.12);

$pre-coaching: rgba(249, 198, 66, 0.5);
$post-coaching: rgba(74, 165, 100, 0.5);

$status-ok: #4AA564;
$status-error: #BA1B1B;
$status-unknown: #43474E;

$top-bar-height: 48px;

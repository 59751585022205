@import "assets/color_palette";

body {
  margin: 0;
  font-family: "Noto Sans JP", Roboto;
  min-height: 100svh;
}

.mat-mdc-form-field {
  line-height: 1.4;
}

pre {
  font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
}

// Safariで::ng-deepが効かないため、やむを得ずここに記述した
// 本来はright-navigation.component.scssに記述すべき内容
.menu-btn > .menu-icon {
  > span.mat-badge-content.mat-badge-active {
    font-size: 10px;
  }
}


.mat-mdc-dialog-container {

  // mat-dialog-contentの文字色を変更
  // 詳細度を上げるため、.mat-mdc-dialog-contentを指定
  .mat-mdc-dialog-content.mdc-dialog__content {
    color: $dark-primary-text;
  }

  .mat-mdc-dialog-actions {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
  }
}

// mat-option直下が幅指定のないspan要素のため
.mat-mdc-option > .mdc-list-item__primary-text {
  width: 100%;
}

// yyyyの下端が見切れるため
input[type="datetime-local"] {
  overflow: visible;
}

// FIXME: Angular Materialのバグ回避策としてmargin-topを追加
// see: https://github.com/angular/components/issues/26352
mat-form-field.mat-mdc-form-field.mat-form-field-appearance-outline {
  margin-top: 4px;
}

// CdkTextareaAutosizeの行指定数どおりの高さにならないバグがある(v16時点)
// 回避策としてmin-height（1行分）を設定
// minRows, maxRowsを指定すると、24px * 指定行数 の高さになる
textarea {
  min-height: 24px;
}

$scrollbar-width: 12px;

::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 8px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

@media print {

  html, body {
    height: fit-content !important;
  }

  .no-print {
    display: none !important;
  }

  .print-only-flex {
    display: flex !important;
  }

  .mat-sidenav-content {
    margin-left: 0 !important;
    overflow: visible !important;
  }

  .sn-main-container {
    height: inherit !important; // height: calc(100svh - 48px); だと印刷時に高さが足りないため
  }

  .main-content {
    max-width: inherit !important; //     max-width: calc(100% - ${rightNavWidth}px); だと印刷時に幅が足りないため
  }

  .print-overflow-visible {
    overflow: visible !important;
  }

  .print-width-auto {
    width: auto !important;
    min-width: inherit !important;
    max-width: inherit !important;
  }

  .print-height-fit-content {
    height: fit-content !important;
  }

  .print-background-white {
    background-color: #fff !important;
  }
}
